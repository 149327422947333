.card {
  border-radius: 4px;
  overflow: hidden;
  background: var(--color-white);
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.05);
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;

  .image {
    width: 100%;
    aspect-ratio: 2/1;
    background-size: cover;
    background-position: center;
  }

  .body {
    padding: var(--indent-m);
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .subtitle {
    font-size: var(--font-size-xs);
    color: rgba(0, 0, 0, 0.5);
    margin-top: var(--indent-xs);
  }

  .text {
    margin-top: var(--indent-base);
  }

  p {
    margin-bottom: var(--indent-base);
    font-size: var(--font-size-s);
  }
}

@media (width >= 1024px) {
  .card {
    max-width: unset;
  }
}
