.faq-groups {
  .group {
    margin-top: var(--indent-m);

    .title {
      font-size: var(--font-size-m);
      font-weight: bold;
    }

    .description {
      font-size: var(--font-size-s);
    }
  }

  .questions {
    margin: var(--indent-m) 0;
    padding-left: var(--indent-m);

    .question {
      font-size: var(--font-size-s);

      .q {
        font-weight: bold;
      }

      .a {
        text-style: italic;
      }

      & + & {
        margin-top: var(--indent-m);
      }
    }
  }
}
