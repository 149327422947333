header {
  background: #004a22;
  margin-bottom: 16px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: var(--layout-header-height);

  .inner {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding: 0 20px;
    max-width: var(--layout-max-width);
    width: 100%;
    margin: 0 auto;
  }
}

@media (width >= 1024px) {
}
