.houses {
  display: flex;
  flex-wrap: wrap;
  margin: 40px -16px;

  .item {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 16px;
    text-decoration: none;
    color: #000;
    margin-bottom: var(--indent-m);

    img {
      max-width: 100%;
      border-radius: 10% 12% 18% 2%;
      box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .desc {
    margin-top: 16px;
  }

  .price {
    font-size: 12px;
    color: #666;
  }
}

.house {
  img {
    max-width: 100%;
    border-radius: 10% 12% 18% 2%;
    box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .subtitle {
    margin-bottom: 20px;
  }
}

@media (width >= 1024px) {
  .houses {
    .item {
      flex-basis: 50%;
      max-width: 50%;
    }
  }
}
