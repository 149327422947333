.hero-banner {
  max-width: var(--layout-max-width);
  margin: calc(var(--indent-m) * -1) calc(var(--indent-m) * -1) 40px;
  position: relative;
  overflow: hidden;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.2);

  .image {
    height: 600px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
  }

  .image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 0 var(--indent-xl);
    max-width: 800px;
    color: #fff;
    font-size: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .headline {
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 8px;
    }

    .button {
      display: inline-block;
      border: 1px solid #fff;
      border-radius: 4px;
      text-decoration: none;
      color: #fff;
      margin-top: 32px;
      padding: 8px 16px;
      font-size: 16px;
    }
  }
}

@media (width >= 1024px) {
  .hero-banner {
    margin: 20px auto 40px;
    border-radius: 4px;

    .text {
      padding: 0 80px;
    }
  }
}
