nav {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px;
  }

  a {
    color: #fff;
    font-size: var(--font-size-s);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
  }
}

@media (width >= 1024) {
  nav {
    a {
      font-size: var(--font-size-base);
    }
  }
}
