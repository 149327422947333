@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: arial, sans-serif;
}

:root {
  --layout-max-width: 1400px;

  --media-screen-s: 480px;
  --media-screen-m: 768px;
  --media-screen-l: 1200px;
  --media-screen-xl: 1440px;

  --indent-xs: 4px;
  --indent-s: 8px;
  --indent-base: 16px;
  --indent-m: 20px;
  --indent-l: 24px;
  --indent-xl: 32px;

  --color-white: #fff;

  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-base: 16px;
  --font-size-m: 20px;
  --font-size-l: 24px;
  --font-size-xl: 32px;
  --font-size-xxl: 40px;

  --layout-header-height: 80px;
}

html,
body,
#root,
.wrapper {
  height: 100%;
}

body {
  background: #f2f6f8;
  padding-top: calc(var(--layout-header-height) + var(--indent-base));
}

h1 {
  font-family: 'Maitree';
}

.wrapper {
  display: flex;
  flex-direction: column;
}

main {
  padding: 0 var(--indent-m);
  flex: 1;
  max-width: var(--layout-max-width);
  width: 100%;
  margin: 0 auto;
}
