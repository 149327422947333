.logo {
  --logo-color: #fff;

  display: block;
  color: var(--logo-color);
  max-width: 70px;
  text-align: center;
  text-decoration: none;
  margin: 0;

  .top {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid var(--logo-color);
  }

  .bottom {
    text-transform: uppercase;
    font-size: 15px;
    margin-top: 5px;
  }

  footer & {
    --logo-color: #666;

    margin: 0 auto var(--indent-xl);
  }
}
