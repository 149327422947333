.boxes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--indent-base) * -1);

  .item {
    flex: 0 0 100%;
    padding: 0 var(--indent-base);
    margin-bottom: var(--indent-l);
  }
}
